import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    subTotal: '0.00',
    totalSeguro: '0.00',
    total: '0.00',
    glosa: '',
    glosaTipo: false,
    listaBadges: [],
    seguroDobleSelected: null,
  },
  getters: {
    getField,
    getSubTotal: (state) => state.subTotal,
    getTotal(state) {
      return parseFloat(state.subTotal) - parseFloat(state.totalSeguro);
    },
    getGlosa: (state) => state.glosa,
    getGlosaTipo: (state) => state.glosaTipo,
    getListaBadges: (state) => state.listaBadges,
  },
  mutations: {
    updateField,
    setSubTotal: (state, data) => { state.subTotal = data; },
    setTotal: (state, data) => { state.total = data; },
    setGlosa: (state, data) => { state.glosa = data; },
    setGlosaTipo: (state, data) => { state.glosaTipo = data; },
    setListaBadges: (state, data) => { state.listaBadges = data; },
    setTotalSeguro: (state, data) => { state.totalSeguro = data; },
    setSeguroDobleSelected: (state, data) => { state.seguroDobleSelected = data; },
    addItemListaBadges: (state, item) => state.listaBadges.push(item),
  },
  actions: {
    cargarSeguroDobleSelectedById({ commit, rootState }, id) {
      if (id === null) {
        return;
      }
      const item = rootState.formFactura.comboAseguradora.find((el) => el.id === id);
      commit('setSeguroDobleSelected', item);
    },
    setInsigniasFormaPagoContadoDefault({ commit, rootState, rootGetters }) {
      const BADGES_CONTADO_DEFAULT = [
        { nombre: rootGetters['formaPagoModal/getNameFormaPagoSelected'] },
        { nombre: rootState.formaPagoModal.modalComboSelected.nombre },
        { nombre: rootState.formaPagoModal.modalComboMedioPagoSelected.nombre },
      ];
      commit('setListaBadges', BADGES_CONTADO_DEFAULT);
    },
    setInsigniasFormaPagoFacturacionTercero({ commit, rootState }) {
      const CAJA = rootState.formaPagoFacturaTercero.caja;
      const BADGE_LIST = [{
        nombre: `Caja: ${CAJA}`,
      }];
      commit('setListaBadges', BADGE_LIST);
    },
    cargarInsigniasFormaPagoEdit({
      state,
      commit,
      rootState,
      rootGetters,
    }) {
      if (!rootState.formFactura.checkFacturaTercero) {
        if (rootState.formaPagoModal.checkCXP) {
          let limite = 0.00;
          rootState.formaPagoModal.ajustesEnUso.forEach((item) => {
            limite = parseFloat(limite) + parseFloat(item.monto);
          });
          if (limite === state.total) {
            rootState.formaPagoModal.ajustesEnUso.forEach((item) => {
              const monto = parseFloat(item.monto).toFixed(2);
              const nombre = `Ajuste: ${item.origen_type} ${item.numero} ${monto}`;
              const BADGE_CXP = { nombre };
              commit('addItemListaBadges', BADGE_CXP);
            });
          } else {
            const CONTADO = 1;
            const BADGES_LIST = [];
            BADGES_LIST.push(
              { nombre: rootGetters['formaPagoModal/getNameFormaPagoSelected'] },
            );
            if (rootState.formaPagoModal.radioFormaPagoselected === CONTADO) {
              BADGES_LIST.push(
                { nombre: rootState.formaPagoModal.modalComboSelected.nombre },
              );
              BADGES_LIST.push(
                { nombre: rootState.formaPagoModal.modalComboMedioPagoSelected.nombre },
              );
            } else {
              BADGES_LIST.push(
                { nombre: `${rootGetters['formaPagoModal/getInputDiasCredito']} Días` },
              );
              BADGES_LIST.push(
                { nombre: `Venc.: ${rootState.formaPagoModal.fechaCreditoVencimiento}` },
              );
            }
            commit('setListaBadges', BADGES_LIST);
            rootState.formaPagoModal.ajustesEnUso.forEach((item) => {
              const monto = parseFloat(item.monto).toFixed(2);
              const nombre = `Ajuste: ${item.origen_type} ${item.numero} ${monto}`;
              const BADGE_CXP = { nombre };
              commit('addItemListaBadges', BADGE_CXP);
            });
          }
        } else {
          const CONTADO = 1;
          const BADGES_LIST = [];
          BADGES_LIST.push(
            { nombre: rootGetters['formaPagoModal/getNameFormaPagoSelected'] },
          );
          if (rootState.formaPagoModal.radioFormaPagoselected === CONTADO) {
            BADGES_LIST.push(
              { nombre: rootState.formaPagoModal.modalComboSelected.nombre },
            );
            BADGES_LIST.push(
              { nombre: rootState.formaPagoModal.modalComboMedioPagoSelected.nombre },
            );
          } else {
            BADGES_LIST.push(
              { nombre: `${rootGetters['formaPagoModal/getInputDiasCredito']} Días` },
            );
            BADGES_LIST.push(
              { nombre: `Venc.: ${rootState.formaPagoModal.fechaCreditoVencimiento}` },
            );
          }
          commit('setListaBadges', BADGES_LIST);
        }
      } else {
        /* 1 */
        const CAJA = rootState.formaPagoFacturaTercero.caja;
        if (CAJA > 0) {
          const BADGE_CAJA = { nombre: `Caja: ${parseFloat(CAJA).toFixed(2)}` };
          commit('addItemListaBadges', BADGE_CAJA);
        }
        /* 2 */
        const BANCO = rootState.formaPagoFacturaTercero.banco;
        if (BANCO > 0) {
          const BADGE_BANCO = { nombre: `Banco: ${parseFloat(BANCO).toFixed(2)}` };
          commit('addItemListaBadges', BADGE_BANCO);
        }
        /* 3 */
        const TARJETA = rootState.formaPagoFacturaTercero.tarjeta;
        if (TARJETA > 0) {
          const BADGE_TARJETA = { nombre: `Tarjeta: ${parseFloat(TARJETA).toFixed(2)}` };
          commit('addItemListaBadges', BADGE_TARJETA);
        }
      }
    },
    actualizarSubTotal({
      commit, state, rootState, rootGetters,
    }) {
      let subTotal = 0.00;
      rootGetters['tableAndAddService/getListaTabla'].forEach((item) => {
        subTotal = parseFloat(subTotal) + parseFloat(item.total);
      });
      commit('setSubTotal', subTotal);
      const TOTAL = parseFloat(subTotal) - parseFloat(state.totalSeguro) - 0; // 0 será desc.
      commit('setTotal', subTotal);
      commit('formaPagoModal/setMonto1', TOTAL, { root: true });
      if (rootState.formFactura.checkFacturaTercero) {
        commit('formaPagoFacturaTercero/setCaja', TOTAL, { root: true });
      }
    },
    actualizarTotalSeguro({ commit, rootState }) {
      const ACUMULADOR_INIT = 0.00;
      const detalleFacturaList = rootState.tableAndAddService.listaTabla;
      const TOTAL_SEGURO = detalleFacturaList.reduce((acumulador, item) => (
        acumulador + (parseFloat(item.seguro) * item.cantidad)
      ), ACUMULADOR_INIT);
      commit('setTotalSeguro', TOTAL_SEGURO);
    },
    resetDetalleFactura({ commit }) {
      commit('setSubTotal', '0.00');
      commit('setTotalSeguro', '0.00');
      commit('setTotal', '0.00');
      commit('setGlosa', '');
      commit('setGlosaTipo', false);
      commit('setListaBadges', []);
      commit('setSeguroDobleSelected', null);
    },
  },
};

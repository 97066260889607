export default () => ({
  tipoViaAdministracions: [
    {
      id: 1,
      nombre: 'Oral',
    },
    {
      id: 2,
      nombre: 'Instramuscular',
    },
    {
      id: 3,
      nombre: 'EndoVenosa',
    },
    {
      id: 4,
      nombre: 'Sub Cutanea',
    },
  ],
  tipoViaAdministracionSelected: null,
  almacenes: [],
  almacenSelected: null,
  fecha: new Date(),
  monedas: [],
  monedaPrincipal: null,
  productos: [],
  productoSelected: null,
  filterProducto: null,
  temporaPageComboProducto: 0,
  totalRegistroProducto: 0,
  stockSaldoUp: 0,
  stockSaldoUa: 0,
  unidad: false,
  stockLotes: [],
  clasificacionCies: [],
  diagnostico1Selected: null,
  diagnostico2Selected: null,
  diagnostico3Selected: null,
  diagnostico4Selected: null,
  nombreDiagnostico: 'S/N',

  tratamiento: null,
  examenFisico: null,
  enfermedadActual: null,
  motivoConsulta: null,
  descripcionDiagnostico1: null,
  descripcionDiagnostico2: null,
  descripcionDiagnostico3: null,
  descripcionDiagnostico4: null,
  consulta: null,
  antecedente: null,
  consultaCierre: null,
  paciente: null,

  cantidad_diagnostico: null,

  lisServiciosComplementarios: [],
});

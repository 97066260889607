import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  async getDatoinicial({ commit, state }, id) {
    try {
      if (!id) {
        util.showNotify('El usuario no tiene vinculado un medico', 'warn');
        return;
      }
      const data = {
        params: {
          page: state.paginaActual,
          per_page: state.porPagina,
          filter_key: state.busqueda,
          estado: (state.stateSelected === 1),
          medico_id: id,
        },
      };
      const RESPONSE = await axios.get('/clinic/consultation/patient', data);
      const PACIENTES = RESPONSE.data.data.data;
      commit('setPacientes', PACIENTES);
      commit('setMedico', RESPONSE.data.data.medico);
      commit('setPerPage', RESPONSE.data.data.per_page);
      commit('setTotal', RESPONSE.data.data.total);
    } catch (error) {
      const FALLO_VALIDACION = 422;
      if (error.response.status !== FALLO_VALIDACION) {
        util.showNotify(error.response.data.message, 'error');
      } else {
        Object.entries(error.response.data.data).forEach(([, mensajes]) => {
          mensajes.forEach((texto) => util.showNotify(texto, 'error'));
        });
      }
    }
  },
  async getListarAtencionPaciente({ commit, state }, dto) {
    try {
      const data = {
        params: {
          page: state.paginaActual,
          per_page: state.porPagina,
          filter_key: state.busqueda,
          state: state.estado,
          medico_id: dto.id,
          tipo_atencion: dto.state_selected,
        },
      };
      const RESPONSE = await axios.get('/clinic/consulta_externa/atencion_paciente/index', data);
      commit('setPacienteAtendidos', RESPONSE.data.data.atencion_paciente.data);
      commit('setPerPage', RESPONSE.data.data.atencion_paciente.per_page);
      commit('setTotal', RESPONSE.data.data.atencion_paciente.total);
      commit('setMedico', RESPONSE.data.data.medico);
    } catch (error) {
      const FALLO_VALIDACION = 422;
      if (error.response.status !== FALLO_VALIDACION) {
        util.showNotify(error.response.data.message, 'error');
      } else {
        Object.entries(error.response.data.data).forEach(([, mensajes]) => {
          mensajes.forEach((texto) => util.showNotify(texto, 'error'));
        });
      }
    }
  },
  resetStatePaciente({ commit }) {
    commit('setPacientes', []);
    commit('setMedico', null);
    commit('setStateSelected', 1);
    commit('setTotal', null);
    commit('setPerPage', 50);
    commit('setPacienteAtendidos', []);
  },
};
